import React from "react"
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { BlockRenderer, Grid, LinkMask, Margin, PageWrapper, Picture, Section, } from '@components/atoms'
import { GridItem } from '@components/molecules'
import { TransitionMask } from '@components/organisms'
import { WithData } from '@utils'
import { ProjectProps } from '@types'
import { type } from '@theme'



const ProjectTemplate = ({ 
  data: {
    sanityPost: { 
      title, 
      description,
      shareImage,
      moreProjects,
      gridItems,
      square,
      logo,
      _rawBuddyData,
    },
  },
}: ProjectProps): React.ReactElement => {
  const meta = [
    { property: "og:title", content: title },
    { name: "twitter:title", content: title },
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },    
    { property: "og:image", content:  `${shareImage?.asset.url}?w=640&h=640&fm=jpg&q=75` },
    { name: "twitter:image", content:  `${shareImage?.asset.url}?w=640&h=640&fm=jpg&q=75` },
  ]

  return (
    <PageWrapper>
      <Helmet {...{meta, title}} >
        <title>the buddy system: {title}</title>
      </Helmet>
            
      <Margin>
        <Article>
          <StyledGrid>
            
            <DataBlock >
              <BuddyLogo src={logo?.asset.url} alt="logo" {...{ square }} />
              {_rawBuddyData && (
                <BlockRenderer>{_rawBuddyData}</BlockRenderer>
              )}
            </DataBlock>
            
            {gridItems.map((item: any) => (
              <GridItem 
                key={item._key} 
                {...item} 
              >
                {item.description && (
                  <ImageDescription>{item.description}</ImageDescription>
                )}
              </GridItem>
            ))}
            
          </StyledGrid>
        </Article>
      </Margin>
      
      {moreProjects && moreProjects.length > 0 && (
        <Margin>
          <Section title="Related Brands & Venues" >
            <Grid>
              {moreProjects.filter(p => !!p).map(project => (
                <Project key={project.slug.current} >
                  <LinkMask slug={project.slug} />
                  <Picture 
                    src={project.shareImage?.asset.url}
                    alt={project.description}
                  />
                  <ProjectTitle className="linkHighlight" >{project.title}</ProjectTitle>
                </Project>
              ))}
            </Grid>
          </Section>
        </Margin>
      )}
      
      <TransitionMask />
    </PageWrapper>
  )
}


const Project = styled.div`
  position: relative;
  grid-column-end: span 3;
`


const ProjectTitle = styled.div`
  margin-top: 1em;
  ${type.headline3}
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: rgba(255, 255, 255, 0);
  text-underline-offset: 0.1em;
  transition: text-decoration-color 0.35s ease-in-out;
`


const Article = styled.article`
  padding: 50px 0;
`


const DataBlock = styled.div`
  h1:first-of-type, h2:first-of-type {
    margin-top: 0;
  }
  @media only screen and (max-width: 743px) {
    grid-row: 2 / 2;
    grid-column-end: span 6;
    margin-bottom: 1em;
  }
  @media only screen and (min-width: 744px) {
    grid-column-end: span 3;
  }
  > * {
    max-width: 100%;
  }
  dl {
    display: flex;
    flex-wrap: wrap;
    dt {
      margin-top: 1em;
      width: 35%;
    }
    dd {
      margin-top: 1em;
      width: 65%;
    }
  }
  ${type.paragraph}
`


const BuddyLogo = styled.img<{ square?: boolean }>`
  ${props => props.square && `
    width: 70%;
    height: auto;
  `}
  ${props => !props.square && `
    width: 85%;
  `}
  margin: 0 auto 4em auto;
`


const ImageDescription = styled.div`
  margin: 1em 0 1em 0;
  @media only screen and (min-width: 744px) {    
    max-width: 300px;
    margin: 0 0 0 auto;
  }
  ${type.pSerifSmall}
`



const StyledGrid = styled(props => <Grid {...props} />)`
  @media only screen and (max-width: 743px) {
    grid-row-gap: 30px;
  }
`



const mapDataToProps = ({ sanityPost }: any) => ({ ...sanityPost })


export const query = graphql`
  query project($slug: String!) {
    sanityPost(slug: {current: {eq: $slug}}) {
      slug {
        current
      }
      title
      description
      _rawBuddyData
      square
      logo {
        asset {
          url
        }
      }
      shareImage {
        asset {
          url
        }
      }
      
      gridItems {
        ... on SanityGridItem {
          ...gridItemFields
        }
        ... on SanityGridItemStrip {
          ...gridItemStripFields
        }
      }
      
      moreProjects {
        title
        slug {
          current
        }
        description
        shareImage {
          asset {
            url
            metadata {
              dimensions {
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`

export default WithData(mapDataToProps, ProjectTemplate)